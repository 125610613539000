const env = {
	base_url: 'https://integrations.getravenbank.com/v1/',
	// bankbox_api: 'https://partners.getravenbank.com/', // production ------
	bankbox_api: 'https://partners.getravenpos.com/',  // staging ----------
	// bankbox_api: "https://1a1e-102-36-229-83.ngrok-free.app/" 
};

export default env;

// 'https://integrations.getravenbank.com/v1/'
// http://partners.getravenpos.com
// https://postest.getravenpos.com
// staging --- https://partners.getravenpos.com/
// https://4173-102-36-229-83.ngrok-free.app
// https://console.bankbox.africa/
// production ----- https://partners.getravenbank.com/
    