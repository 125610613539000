import React, { useState } from 'react';
import ContainerLayout from '../../../../../layout/dashboard/container';
import '../styles/index.css';
import { icons } from '../../../../../assets/icons';
import {
	capitalizeAllLetter,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNairaSymbol,
	formatNumWithCommaNairaSymbolTwo,
	getActionNamesByPolicyName,
	reactSelectStyle,
	returnStatus,
	symbol,
	trimLongString,
	validateStringForTerminalId,
} from '../../../../../utils/helper/Helper';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenToggleSlide,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';
import RootState, {
	AssignBankBoxPayload,
	BankBoxUser,
} from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import CameraAssignBox from '../cameraAssign/CameraAssignBox';
import UpdateMerchantFeeUserModal from '../../components/modals/UpdateFeeMerchantModal';
import UpdateBankboxUserModal from '../../components/modals/UpdateBankboxUserModal';
import { useOnClickOutside } from '../../../../../utils/helper/UseOnClickOutside';
import ExportModal from '../../../../../components/common/exportModal';
import SettleMentAccountModal from './SettleMentAccountModal';
import CheckUrlFragment from '../../../../../components/common/CheckUrlCodeFragment';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import ProccessSettleMentAccountModal from './ProcessSettlementMerchant';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';

const BankBoxUserPreview = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const [autoAssign, setAutoAssign] = useState(true);
	const [editMerchantFeeModal, setEditMerchantFeeModal] =
		useState<boolean>(false);
	const [uploadViaCamera, setUploadViaCamera] = useState(false);
	const [editMerchantModal, setEditMerchantModal] = useState<boolean>(false);
	const [requestSettleMerchantModal, setRequestSettleMerchantModal] =
		useState<boolean>(false);
	const [singleChild, setSingleChild] = useState<any>({});
	interface formComponent {
		state: any;
		lga: any;
	}
	const [details, setDetails] = useState<formComponent>({
		state: '',
		lga: '',
	});
	const [terminalInfo, setTerminalInfo] = useState<any>({});
	const [showActivate, setShowactivate] = useState<boolean>(false);
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
			lga: '',
		},
	});
	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		// affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		label: '',
		poseidon_business_name: '',
		exist_nibbs: true,
		// term_id: '',
		// auto_gen: true,
		auto_generate: true,
		is_nibss: true,
		terminal_id: '',
	});
	const { profile } = useSelector((state: RootState) => state.settings);

	const { all_states, all_lga, banks, user_merchant_settlement } = useSelector(
		(state: RootState) => state.bankbox
	);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [terminalDetails, setTerminalDetails] = useState<any>({});
	const [singleChildFee, setSingleChildFee] = useState<any>({});
	// const [editMerchantFeeModal, setEditMerchantFeeModal] =
	// 	useState<boolean>(false);
	// const email = searchParams.get('user_email');
	const merchant_email = localStorage?.getItem('merchant-email-user-preview');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [selectedType, setSelectedType] = useState('Yes, It is');
	const selectTypeList = ['Yes, It is', 'No, it’s not'];
	// const { all_fees_no_page } = useSelector((state: RootState) => state.bankbox);
	const {
		loading,
		user_merchant,
		single_user_bankboxes,
		single_user_requests,
	} = useSelector((state: RootState) => state.bankbox);
	const [refreshTable, setRefreshTable] = useState(false);
	React.useEffect(() => {
		if (merchant_email) {
			getSingleDetails();
		} else {
			navigate('dashboard-bankbox/users');
		}
	}, [refreshTable]);
	// React.useEffect(() => {
	// 	if (merchant_email && !editMerchantFeeModal) {
	// 		getSingleDetails();
	// 	}
	// }, [editMerchantFeeModal]);
	const [feeDetail, setFeeDetail] = useState<any>({});
	const getSingleDetails = async () => {
		const data = await dispatch(
			bankboxAPI.getSingleUserMerchant({ email: merchant_email as string })
		);
		// console.log(data);
		if (data?.payload?.status === 'success') {
			const objFee = data?.payload?.data?.data?.fees;
			const user = data?.payload?.data?.data?.user;
			// console.log(data?.payload?.data?.data);

			const newObj = { ...user, fee_ref: objFee?.id };
			// console.log(newObj);
			setSingleChildFee(newObj);
			setFeeDetail(objFee);
		}
	};

	const [el, setEl] = React.useState<any>({
		id: 0,
		fname: '',
		lname: '',
		poseidon_email: '',
		bvn: '',
		nin: '',
		phone: '',
		affiliate_app: '',
		affiliate_app_id: '',
		poseidon_business_name: '',
		poseidon_business_address: '',
		poseidon_business_type: null,
		poseidon_business_description: '',
		poseidon_profile_reference: '',
		business_category: '',
		region: '',
		created_at: '',
		updated_at: '',
		ref_fee: '',
	});

	React.useEffect(() => {
		setEl(user_merchant?.user);
	}, [user_merchant]);

	const infoIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_164_20412)">
				<path
					d="M7.99967 10.6668V8.00016M7.99967 5.3335H8.00634M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
					stroke="black"
					strokeWidth="1.2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_164_20412">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);

	const formatState = (param: any) => {
		if (param?.length > 0) {
			const newList = param?.map((chi: any) => {
				return { label: chi, value: chi };
			});
			return newList;
		}
	};
	React.useEffect(() => {
		// getFirstFetch();
		if (banks?.length < 1) {
			dispatch(bankboxAPI.getBankboxBanks({}));
		}
	}, []);
	// const getFirstFetch = async () => {
	// 	dispatch(
	// 		bankboxAPI.getAllFeesWithoutPagination({
	// 			per_page: 20,
	// 			page: 1,
	// 			// affiliate_app_id:
	// 		})
	// 	);
	// };

	const all_bankbox = single_user_bankboxes;
	const [rightContent, setRightContent] = useState('Devices');
	const rightList = ['Devices', 'Requests'];

	const [showTerminalError, setShowTerminalError] = useState(false);
	async function handleAssign() {
		if (
			!validateStringForTerminalId(assignFormData?.terminal_id) &&
			!autoAssign
		) {
			toast.error(
				'Terminal ID must be exactly 8 characters, all capitalized and alphanumeric.'
			);
			setShowTerminalError(true);
			return;
		}

		const obj = {
			...assignFormData,
			auto_generate: autoAssign,
			is_nibss: autoAssign ? false : assignFormData?.exist_nibbs,
			terminal_id: assignFormData?.terminal_id,
			state: details?.state?.value,
			city: details?.lga,
			poseidon_business_name: user_merchant?.user?.poseidon_business_name,
			poseidon_business_email: user_merchant?.user?.poseidon_email,
		};
		// console.log(obj);
		//  console.log(user_merchant);

		const resp = await dispatch(bankboxAPI.assignBankbox(obj));

		if (resp.payload?.status === 'success') {
			setAssignBankbox({
				...assignBankbox,
				on: false,
			});
			const email = searchParams.get('user_email');
			dispatch(
				bankboxAPI.getSingleUserMerchant({ email: merchant_email as string })
			);
			setDetails({ lga: '', state: '' });
			setAssignFormData({
				exist_nibbs: true,
				// affiliate_app_id: '',
				poseidon_business_email: '',
				serial_number: '',
				label: '',
				poseidon_business_name: '',
				auto_generate: false,
				is_nibss: true,
				terminal_id: '',
			});
		}
	}

	const userIcon = (
		<svg
			className="img"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.93756 2.43811C9.71124 2.83185 10.2429 3.64946 10.2429 4.59424C10.2429 5.53902 9.71124 6.35662 8.93756 6.75037M9.98186 9.54918C10.7711 9.9149 11.4817 10.5109 12.0705 11.2783M1.62744 11.2783C2.6438 9.95353 4.02369 9.13938 5.54357 9.13938C7.06346 9.13938 8.44335 9.95353 9.45971 11.2783M7.89325 4.59424C7.89325 5.92317 6.84127 7.00049 5.54357 7.00049C4.24588 7.00049 3.19389 5.92317 3.19389 4.59424C3.19389 3.2653 4.24588 2.18799 5.54357 2.18799C6.84127 2.18799 7.89325 3.2653 7.89325 4.59424Z"
				stroke="#8B8B8B"
				strokeWidth="1.09705"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const cameraUploadIcon = (
		<svg
			className="img"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="dull-bright-filter"
				d="M6 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V6M6 19H5.8C4.11984 19 3.27976 19 2.63803 18.673C2.07354 18.3854 1.6146 17.9265 1.32698 17.362C1 16.7202 1 15.8802 1 14.2V14M19 6V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H14M19 14V14.2C19 15.8802 19 16.7202 18.673 17.362C18.3854 17.9265 17.9265 18.3854 17.362 18.673C16.7202 19 15.8802 19 14.2 19H14M1 10H1.01M5.5 10H5.51M14.5 10H14.51M10 10H10.01M19 10H19.01"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const editIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	function formatStatus(status: string | number) {
		let s;
		// 		0 = pending, 1 = approved, 2 = dispatched, 3 = delivered
		// [3:17 PM] 4 = declined
		if (status === 0 || status === 'pending') {
			s = 'pending';
		}
		if (status === 1) {
			s = 'approved';
		}
		if (status === 2) {
			s = 'dispatched';
		}
		if (status === 3) {
			s = 'delivered';
		}
		if (status === 4) {
			s = 'declined';
		}
		return (
			<div className={`transaction-status ${s && s?.toLowerCase()}`}>
				<p style={{ textTransform: 'capitalize' }}>{s}</p>
			</div>
		);
	}

	React.useEffect(() => {
		if (Object?.keys(user_merchant)?.length > 0) {
			// console.log(user?.region);
			setDetails((prev) => {
				return {
					...prev,
					state: {
						label: user_merchant?.user?.region,
						value: user_merchant?.user?.region,
					},
					lga: user_merchant?.user?.lga,
				};
			});
		}
	}, [user_merchant]);

	const dotIcons = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="dull-bright-filter"
				d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
				stroke="#014345"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				className="dull-bright-filter"
				d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
				stroke="#014345"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				className="dull-bright-filter"
				d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
				stroke="#014345"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const [showDrop, setShowDrop] = useState<boolean>(false);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [showSettlement, setShowSettlement] = useState<boolean>(false);
	const testingSEttDropRef = useOnClickOutside(() => {
		setShowDrop(false);
	});

	const assignIcon = (
		<svg
			className="img"
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.5 4.5L8 3M8 3L9.5 1.5M8 3L11 3M8 10.5V9.9C8 9.05992 8 8.63988 7.83651 8.31901C7.6927 8.03677 7.46323 7.8073 7.18099 7.66349C6.86012 7.5 6.44008 7.5 5.6 7.5H3.4C2.55992 7.5 2.13988 7.5 1.81901 7.66349C1.53677 7.8073 1.3073 8.03677 1.16349 8.31901C1 8.63988 1 9.05992 1 9.9V10.5M6.25 3.75C6.25 4.7165 5.4665 5.5 4.5 5.5C3.5335 5.5 2.75 4.7165 2.75 3.75C2.75 2.7835 3.5335 2 4.5 2C5.4665 2 6.25 2.7835 6.25 3.75Z"
				stroke="#014345"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const bankIcon = (
		<svg
			className="img"
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.5 4.50001V8.50001M4.75 4.50001V8.50001M7.25 4.50001V8.50001M9.5 4.50001V8.50001M1.5 9.30001L1.5 9.70001C1.5 9.98004 1.5 10.12 1.5545 10.227C1.60243 10.3211 1.67892 10.3976 1.773 10.4455C1.87996 10.5 2.01997 10.5 2.3 10.5H9.7C9.98003 10.5 10.12 10.5 10.227 10.4455C10.3211 10.3976 10.3976 10.3211 10.4455 10.227C10.5 10.12 10.5 9.98004 10.5 9.70001V9.30001C10.5 9.01998 10.5 8.87997 10.4455 8.77302C10.3976 8.67893 10.3211 8.60244 10.227 8.55451C10.12 8.50001 9.98003 8.50001 9.7 8.50001H2.3C2.01997 8.50001 1.87996 8.50001 1.773 8.55451C1.67892 8.60244 1.60243 8.67893 1.5545 8.77301C1.5 8.87997 1.5 9.01998 1.5 9.30001ZM5.82646 1.53858L2.12646 2.3608C1.90293 2.41047 1.79116 2.43531 1.70773 2.49541C1.63415 2.54843 1.57636 2.62046 1.54057 2.70379C1.5 2.79827 1.5 2.91276 1.5 3.14175L1.5 3.70001C1.5 3.98004 1.5 4.12005 1.5545 4.22701C1.60243 4.32109 1.67892 4.39758 1.773 4.44551C1.87996 4.50001 2.01997 4.50001 2.3 4.50001H9.7C9.98003 4.50001 10.12 4.50001 10.227 4.44551C10.3211 4.39758 10.3976 4.32109 10.4455 4.22701C10.5 4.12005 10.5 3.98004 10.5 3.70001V3.14175C10.5 2.91276 10.5 2.79827 10.4594 2.70379C10.4236 2.62046 10.3659 2.54843 10.2923 2.49541C10.2088 2.43531 10.0971 2.41047 9.87354 2.3608L6.17354 1.53858C6.10878 1.52418 6.0764 1.51699 6.04368 1.51412C6.01461 1.51157 5.98538 1.51157 5.95632 1.51412C5.9236 1.51699 5.89122 1.52418 5.82646 1.53858Z"
				stroke="#014345"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const arrowIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				// className="dull-bright-filter"
				d="M6 18L18 6M18 6H10M18 6V14"
				stroke={theme === 'light' ? `#014345` : `#ffffff`}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const optionList = [
		{
			name: 'Assign Terminal',
			icon: assignIcon,
			onClick: (param?: any) => {
				setAssignBankbox({
					on: true,
					chi: user_merchant?.user,
				});
				setShowDrop(false);
			},
			hide: getActionNamesByPolicyName(
				profile?.rolePolicies?.policies,
				'terminals'
			)?.includes('assign_terminal')
				? false
				: true,
		},
		{
			name: 'Request settlement',
			icon: arrowIcon,
			onClick: (param?: any) => {
				setRequestSettleMerchantModal(true);
				setShowDrop(false);
			},
			hide: Object?.keys(user_merchant_settlement)?.length < 1 ? true : false,
		},
		{
			name: ` ${
				Object?.keys(user_merchant_settlement)?.length < 1 ? `Add` : `Edit`
			} Settlement Account`,
			icon:
				Object?.keys(user_merchant_settlement)?.length < 1
					? bankIcon
					: editIcon,
			onClick: () => {
				setShowSettlement(true);
				setShowDrop(false);
			},
			hide:
				getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'settlement'
				)?.includes('add_merchant_settlement_account') ||
				getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'settlement'
				)?.includes('edit_merchant_settlement_account')
					? false
					: true,
		},
		{
			name: `Change Merchant Fee`,
			icon: editIcon,
			onClick: () => {
				setEditMerchantFeeModal(true);
				setShowDrop(false);
			},
			hide: getActionNamesByPolicyName(
				profile?.rolePolicies?.policies,
				'fee details'
			)?.includes('change_fees')
				? false
				: true,
		},
	];

	async function handleDeactivate() {
		const resp = await dispatch(
			bankboxAPI.deactivateBankbox({ serial_no: terminalInfo?.serial })
		);

		if (resp.payload.status === 'success') {
			getSingleDetails();
			setShowactivate(false);
		}
	}

	async function handleReactivate() {
		const resp = await dispatch(
			bankboxAPI.reactivateBankbox({ serial_no: terminalInfo?.serial })
		);

		if (resp.payload.status === 'success') {
			getSingleDetails();
			setShowactivate(false);
		}
	}

	return (
		<>
			<DashboardLayout
				back
				onBack={() => {
					if (navigate.length > 1) {
						navigate(-1);
					} else {
						navigate('/dashboard-bankbox/users');
					}
				}}
			>
				<ContainerLayout
					pageTitle="Merchant Details"
					pageMode
					loading={loading}
					loadingText="Fetching user..."
					// onBack={() => {
					// if (navigate.length > 1) {
					// 	navigate(-1);
					// } else {
					// 	navigate('/dashboard-bankbox/users');
					// }
					// }}
					disableBack={true}
					style={{
						height: '100%',
						backgroundColor: loading ? 'white' : 'unset',
						borderRadius: '1rem',
					}}
					topRightContent={
						!loading && (
							<div
								ref={testingSEttDropRef}
								className="single-user-drop-drown-icon-wrap "
							>
								<div
									onClick={() => {
										setShowDrop(!showDrop);
									}}
									className="icon-wrap white-black-bg card-bg border-theme"
								>
									<figure className="img-box">{dotIcons}</figure>
								</div>
								{showDrop && (
									<div className="drop-wrap-box white-black-bg card-bg border-theme">
										{optionList?.map((chi, idx) => {
											const { icon, name, onClick, hide } = chi;
											if (hide) {
												return <React.Fragment key={idx}></React.Fragment>;
											}
											return (
												<div
													onClick={() => {
														onClick(user_merchant?.user);
													}}
													className="item-row"
													key={idx}
												>
													<div className="icon-box">
														<figure className="img-box grey-bg">{icon}</figure>
													</div>
													<p className="name grey-white-color">{name}</p>
												</div>
											);
										})}
									</div>
								)}
							</div>
						)
						// <RavenButton
						// 	onClick={() => {
						// 		setAssignBankbox({
						// 			on: true,
						// 			chi: user,
						// 		});
						// 	}}
						// 	color="deep-green-light"
						// 	label="Assign BankBox"
						// 	className="bankbox-assign-btn"
						// />
					}
					pageSubtitle="All details about this merchant"
				>
					<div
						// // onClick={() => {
						// // 	// console.log(all_fees_no_page);
						// 	console.log(user_merchant);
						// // }}
						style={{ marginTop: '-1.5rem' }}
						className="bankbox-user-view"
					>
						<div className="bankbox-user-view__left">
							<div className="bankbox-user-view__left--title">
								<div className="left">
									{' '}
									<h6>{capitalizeAllLetter(el?.poseidon_business_name)}</h6>
									<p>{el?.poseidon_email}</p>
								</div>
								<div className="right">
									{getActionNamesByPolicyName(
										profile?.rolePolicies?.policies,
										'merchants'
									)?.includes('edit_merchant') && (
										<RavenButton
											onClick={() => {
												setSingleChild(el);
												setEditMerchantModal(true);
												// onEdit && onEdit(el);
												// console.log(el);
											}}
											color="deep-green-light"
											label="Edit Merchant"
											className="btn-outline-deep-green-light"
											textColor="black-light"
											size="small"
										>
											<figure className="img-box">{editIcon}</figure>
										</RavenButton>
									)}
								</div>
							</div>
							<div className="bankbox-user-view__left--date-location">
								<div className="item">
									<figure>{icons.calendar_rounded_grey}</figure>
									<p>
										<DateFragmentBox date={el?.created_at} />
									</p>
								</div>

								<div className="item">
									<figure>{icons.location_rounded_grey}</figure>
									<p>{el?.region}</p>
								</div>
								{/* <div className="item">
								<figure>{icons.location_rounded_grey}</figure>
								<p>{el?.region}</p>
							</div> */}
							</div>
							<div className="bankbox-user-view__left--user-details">
								<div className="details-row">
									<div className="details">
										<span>Total Wallet Balance</span>
										<p>
											{' '}
											{formatNumWithCommaNairaSymbolTwo(
												user_merchant?.user?.wallet_balance
											)}
										</p>
									</div>
									<div className="details">
										<span>Total Transaction Count</span>
										<p>
											{' '}
											{formatNumWithCommaNairaSymbolTwo(
												user_merchant?.transaction_info?.total_count,
												{
													hideSymbol: true,
													hideDecimal: true,
												}
											)}
										</p>
									</div>
								</div>

								<div className="details-row">
									<div className="details">
										<span>Full Name</span>
										<p style={{ textTransform: 'capitalize' }}>
											{' '}
											{`${el?.fname || ""} ${el?.lname || ""}`}
											{/* {el?.fname || "" + ' ' + el?.lname || ""} */}
										</p>
									</div>
									<div className="details">
										<span>Business Phone</span>
										<p> +234 {el?.phone}</p>
									</div>
								</div>

								<div className="details-row">
									<div className="details">
										<span>Business Category</span>
										<p>{el?.business_category}</p>
									</div>
									<div className="details">
										<span>Business Address</span>
										<p>{el?.poseidon_business_address}</p>
									</div>
								</div>
								<div className="details-row">
									{el?.nin && (
										<div className="details">
											<span>User's NIN</span>
											<p>{el?.nin}</p>
										</div>
									)}
									{el?.bvn && (
										<div className="details">
											<span>User's BVN</span>
											<p>{el?.bvn}</p>
										</div>
									)}
								</div>
								<div className="details-row">
									{user_merchant?.settlement_detail?.bank && (
										<div className="details">
											<span>Settlement Details</span>
											<div className="bank-name-box">
												{' '}
												<CheckUrlFragment
													className="icon-wrap card-bg border-theme"
													url={`https://businessapi.getraventest.com/static/media/banks/${user_merchant?.settlement_detail?.bank_code}.png `}
												>
													<figure className="img-box">{bankIcon}</figure>
												</CheckUrlFragment>
												<div className="name-number-box">
													{' '}
													<p className="name black-white-color">
														{user_merchant?.settlement_detail?.bank}
													</p>
													<p className="grey-white-color">
														{`${
															user_merchant?.settlement_detail
																?.account_number || ''
														}  • ${
															trimLongString(
																user_merchant?.settlement_detail?.account_name,
																25
															) || ''
														}`}
													</p>
												</div>
											</div>
										</div>
									)}
									{/* {user_merchant?.settlement_detail?.account_number && (
										<div className="details">
											<span>Settlement Account Number</span>
											<p>{user_merchant?.settlement_detail?.account_number}</p>
										</div>
									)} */}
								</div>
							</div>
							{/* fee detail start */}
							{getActionNamesByPolicyName(
								profile?.rolePolicies?.policies,
								'fee details'
							)?.includes('view_fee_details') && (
								<div className="fee-details-info-box">
									{/* title change box start */}
									<div className="title-change-box border-theme-bottom">
										<p className="title">Fee Details</p>
										{getActionNamesByPolicyName(
											profile?.rolePolicies?.policies,
											'fee details'
										)?.includes('change_fees') && (
											<p
												onClick={() => {
													setEditMerchantFeeModal(true);
												}}
												className="change"
											>
												Change Fee
											</p>
										)}
									</div>
									{/* title change box end */}
									{/* details start */}
									<div
										onClick={() => {
											console.log(user_merchant?.fees);
										}}
										className="detail-box"
									>
										{/* label value box start*/}
										<div className="label-value-box border-theme-right">
											<p className="label grey-white-color">Fee Reference</p>
											<p className="value">
												{user_merchant?.fees?.reference || '----'}
											</p>
										</div>
										{/* label value box end */}
										{/* label value box start*/}
										<div className="label-value-box border-theme-right">
											<p className="label grey-white-color">Transaction Fee</p>
											<p className="value">
												{/* {symbol('ngn') +
												formatNumWithComma(feeDetail?.cap, 'ngn')} */}
												{user_merchant?.fees?.fee || "0"}%
											</p>
										</div>
										{/* label value box end */}
										{/* label value box start*/}
										<div className="label-value-box border-theme-right">
											<p className="label grey-white-color">Fee Cap</p>
											<p className="value">
												{/* {symbol('ngn') +
												formatNumWithComma(feeDetail?.cap, 'ngn')} */}
												{formatNumWithCommaNairaSymbolTwo(
													user_merchant?.fees?.cap
												)}
											</p>
										</div>
										{/* label value box end */}
										{/* label value box start*/}
								
										{/* label value box end */}
										{/* label value box start*/}
										<div className="label-value-box">
											<p className="label grey-white-color">Collection fee</p>
											<p className="value">
												{/* {symbol('ngn') +
												formatNumWithComma(
													feeDetail?.bank_collection_fee,
													'ngn'
												)} */}
												{formatNumWithCommaNairaSymbolTwo(
													user_merchant?.fees?.bank_collection_fee
												)}
											</p>
										</div>
										{/* label value box end */}
									</div>
									{/* details end */}
								</div>
							)}
							{/* fee detail end */}
						</div>
						<div className="bankbox-user-view__right">
							<div className="bankbox-user-view__right--title">
								{/* <figure>{icons.calendar_rounded_grey}</figure>
							<h6>Devices • {all_bankbox?.length}</h6> */}
								{rightList?.map((chi, idx) => {
									return (
										<div
											key={idx}
											className={`select-item-wrap ${
												rightContent === chi &&
												'select-item-wrap-active grey-bg'
											}`}
											onClick={() => {
												rightContent !== chi && setRightContent(chi);
											}}
										>
											<span>{chi}</span>
										</div>
									);
								})}
							</div>
							<div className="bankbox-user-view__right--devices-contain">
								{/* device and request box start */}
								{rightContent === 'Devices' ? (
									<>
										{' '}
										{user_merchant?.bankboxes?.length < 1 ? (
											<div className="empty_all_bb">
												<h6>No Devices Found</h6>
												<p>
													It appears that no bankbox device has been assigned to
													this user yet.
												</p>
											</div>
										) : (
											user_merchant?.bankboxes?.map((chi: any, idx: any) => {
												return (
													<div
														onClick={() => {
															localStorage?.setItem(
																'single-ter-serial',
																chi.serial
															);
															navigate('/dashboard-bankbox/terminals/view');
														}}
														key={idx}
														className="device cursor-pointer"
													>
														<div className="device__business">
															<h6>{chi?.business_name}</h6>
															<p>Serial No: {chi?.serial}</p>
														</div>
														<div
															onClick={(e) => {
																e.stopPropagation();
															}}
															className="badge-user-box"
														>
															<div className="tooltip-hover-wrap">
																{' '}
																<RavenToolTip
																	position={`left`}
																	color="deep-green-light"
																	text={
																		Number(chi?.status) === 1
																			? `Deactivate device ?`
																			: 'Activate  device ?'
																	}
																/>{' '}
																<BadgeComponent
																	className={
																		Number(chi?.status) === 1
																			? `success`
																			: `failed`
																	}
																	onClick={() => {
																		setShowactivate(true);
																		setTerminalInfo(chi);
																	}}
																	text={
																		Number(chi?.status) === 1
																			? `Activated`
																			: `Deactivated`
																	}
																/>
															</div>
														</div>
														<div className="device__owner">
															<div className="device__owner--email">
																<figure>{icons.people_grey}</figure>
																<p>Assignee • {chi?.poseidon_business_email}</p>
															</div>
															<div className="device__owner--trx-volume">
																<p>Volume </p>
																<h6>
																	{formatNumWithCommaNairaSymbolTwo(
																		chi?.volume
																	)}
																</h6>
															</div>
														</div>
													</div>
												);
											})
										)}
									</>
								) : (
									<>
										{user_merchant?.requests?.length > 0 ? (
											<>
												{' '}
												{user_merchant?.requests?.map((chi: any, idx: any) => {
													return (
														<div
															key={idx}
															className="request-box-wrap-card grey-bg"
														>
															<div className="box-wrap-card white-black-bg">
																<div className="top-box">
																	<div className="quantity-ref-box">
																		<p className="quantity grey-white-color">
																			Qty: {chi?.quantity || '0'} Pcs
																		</p>
																		<div className="ref tooltip-hover-wrap">
																			{trimLongString(
																				chi?.affiliate_app_id,
																				20
																			) || '---'}
																			<RavenToolTip
																				position={`top-center`}
																				color={`deep-green-light`}
																				text={chi?.affiliate_app_id}
																			/>
																		</div>
																	</div>
																	<div className="badge-status-box">
																		<BadgeComponent
																			text={formatStatus(chi?.status) as any}
																			className={returnStatus(chi?.status)}
																		/>
																	</div>
																</div>
																<div className="bottom-box border-theme-top">
																	<div className="user-img-box grey-bg">
																		<figure className="img-box">
																			{userIcon}
																		</figure>
																	</div>
																	<p className="email grey-white-color">
																		{chi?.email || '---'}
																	</p>
																</div>
															</div>
														</div>
													);
												})}
											</>
										) : (
											<>
												<div className="empty_all_bb">
													<h6>No Request Found</h6>
													<p>
														It appears that no bankbox device has been requested
														by this user yet.
													</p>
												</div>
											</>
										)}
									</>
								)}
								{/* device and request box end */}
							</div>
						</div>
					</div>
				</ContainerLayout>
			</DashboardLayout>
			<RavenModal
				visble={editMerchantModal}
				onClose={() => setEditMerchantModal(false)}
				className={'bankbox-create-modal'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<UpdateBankboxUserModal
					onComplete={() => {
						setRefreshTable(!refreshTable);
						setEditMerchantModal(false),
							toast.success('BankBox merchant updated successfully');
					}}
					detail={singleChild}
					visible={editMerchantModal}
				/>
			</RavenModal>
			<RavenModal
				visble={editMerchantFeeModal}
				onClose={() => setEditMerchantFeeModal(false)}
				className={'bankbox-create-modal bankbox-create-modal-update-fee'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<UpdateMerchantFeeUserModal
					onComplete={() => {
						setRefreshTable(!refreshTable);
						setEditMerchantFeeModal(false),
							toast.success('BankBox merchant fee updated successfully');
					}}
					detail={singleChildFee}
					feeDetail={feeDetail}
					setFeeDetail={setFeeDetail}
					visible={editMerchantFeeModal}
				/>
			</RavenModal>

			<RavenModal
				onClose={() => {
					setAssignBankbox({ ...assignBankbox, on: false });
					setUploadViaCamera(false);
					setDetails({ state: '', lga: '' });
				}}
				visble={assignBankbox.on}
				// visble={true}
				loading={loading}
				onBtnClick={handleAssign}
				btnColor="deep-green-light"
				btnLabel={!uploadViaCamera ? 'Assign Terminal' : ''}
				className={`session-expired-wrap-modal`}
				disabled={
					autoAssign &&
					(!assignFormData?.label ||
						!assignFormData?.serial_number ||
						!details?.lga ||
						!details?.state)
						? true
						: !autoAssign &&
						  (!assignFormData?.terminal_id ||
								!assignFormData?.label ||
								!details?.lga ||
								!details?.state)
						? true
						: false
				}
			>
				<div
					// onClick={() => {
					// 	// console.log(user);
					// }}
					className="assign-bb-modal"
				>
					<div className="assign-bb-modal__title">
						<h6>Assign BankBox</h6>
						<p>
							{uploadViaCamera
								? `Ensure to capture the BankBox serial number`
								: `Allocate Bankbox to a user`}
						</p>
					</div>
					{/* <MyComponent />  */}
					{/* {!uploadViaCamera && (
						<div
							onClick={() => {
								setUploadViaCamera(true);
							}}
							className="camera-icon-box"
						>
							<div className="camera-icon">
								<figure className="img-box grey-bg">{cameraIcon}</figure>
							</div>
							<p>Use webcam to get bankbox serial no</p>
						</div>
					)} */}
					{uploadViaCamera ? (
						<>
							{/* <WebcamCapture /> */}
							<CameraAssignBox
								onCancel={() => {
									setUploadViaCamera(false);
								}}
								onFinish={(param: any) => {
									setUploadViaCamera(false);
									setTerminalDetails(param);
									setAssignFormData((prev) => {
										return { ...prev, serial_number: param?.actualValue };
									});
								}}
							/>
						</>
					) : (
						<>
							{' '}
							<div className="assign-bb-modal__form-body">
								{/* <RavenInputField
							type="select"
							color="deep-green-light"
							value={assignFormData?.fee_category}
							label="Fee Category"
							style={{ position: 'relative', zIndex: 10 }}
							placeholder="Select Fee Category"
							name="fee_category"
							selectOption={formatFeeCategory()}
							onChange={(e: any) => {
								setAssignFormData((prev) => {
									return { ...prev, fee_category: e };
								});
							}}
						/> */}
								<div className="serial-number-input-form-group">
									<RavenInputField
										type={`account-number`}
										maxLength={10}
										color="deep-green-light"
										value={assignFormData?.serial_number}
										label="BankBox Serial Number"
										onChange={(e: any) => {
											setAssignFormData({
												...assignFormData,
												serial_number: e.target.value,
											});
											if (terminalDetails) {
												setTerminalDetails({});
											}
										}}
										placeholder="Input last 10 digit of your BankBox serial number"
										name="serial"
										// labelSpanText={
										// 	terminalDetails?.fullValue ? (
										// 		((
										// 			<>
										// 				<div
										// 					onClick={() => {
										// 						setUploadViaCamera(true);
										// 					}}
										// 					className="camera-icon-box"
										// 				>
										// 					<div className="camera-icon">
										// 						<figure className="img-box grey-bg">
										// 							{cameraIcon}
										// 						</figure>
										// 					</div>
										// 					<p>Input via webcam</p>
										// 				</div>
										// 			</>
										// 		) as any)
										// 	) : (
										// 		<div
										// 			onClick={() => {
										// 				setUploadViaCamera(true);
										// 			}}
										// 			className="camera-icon-box"
										// 		>
										// 			<div className="camera-icon">
										// 				<figure className="img-box grey-bg">
										// 					{cameraIcon}
										// 				</figure>
										// 			</div>
										// 			<p>Input via webcam </p>
										// 		</div>
										// 	)
										// }
									/>
									<div
										onClick={() => {
											setUploadViaCamera(true);
										}}
										className="upload-toggle-box  white-black-bg border-theme"
									>
										<figure className="img-box tooltip-hover-wrap">
											{cameraUploadIcon}
											<RavenToolTip
												textColor="white-light"
												color={`deep-green-light`}
												position={`bottom-left`}
												text="Enter serial number via webcam "
											/>
										</figure>
									</div>
								</div>

								<RavenInputField
									type="text"
									value={assignFormData?.label}
									onChange={(e: any) => {
										setAssignFormData({
											...assignFormData,
											label: e.target.value,
										});
									}}
									color="deep-green-light"
									label="BankBox Label"
									placeholder="E.g Eatery one"
									name="serial"
								/>
								{/* auto generate box start */}
								{/* two input field box start */}
								<div
									style={{ position: 'relative', zIndex: '10' }}
									className="two-input-field-box"
								>
									<form autoComplete="off" style={{ width: '100%' }} action="">
										<RavenInputField
											menuPlacement={`top`}
											selectStyles={reactSelectStyle}
											color="deep-green-light"
											label="State * "
											type="select"
											placeholder="Select option"
											value={details?.state}
											onChange={(e: any) => {
												setDetails((prev) => {
													return { ...prev, state: e, lga: '' };
												});
												// if
												// dispatch(
												// 	bankboxAPI.getBankboxLgaByStates({ region: e.value })
												// );
											}}
											// disabled={user?.region ? true : false}
											selectOption={formatState(all_states)}
										/>
									</form>
									<form autoComplete="off" style={{ width: '100%' }} action="">
										{' '}
										<RavenInputField
											selectStyles={reactSelectStyle}
											menuPlacement={`top`}
											color="deep-green-light"
											label="City* "
											type="text"
											placeholder="Enter city"
											disabled={!details?.state}
											value={details?.lga}
											onChange={(e: any) => {
												setDetails((prev) => {
													return { ...prev, lga: e?.target?.value };
												});
											}}
											selectOption={formatState(all_lga)}
											// loadingSelect={true}
										/>
									</form>
								</div>
								{/* two input field box end */}
								<div
									style={{ position: 'relative', zIndex: '20' }}
									className="auto-generate-box-wrap"
								>
									<div className="togle-info-box">
										{' '}
										<RavenToggleSlide
											value={autoAssign}
											checked={autoAssign}
											onChange={() => {
												setAutoAssign(!autoAssign);
											}}
											color={`deep-green-light`}
										/>
									</div>

									<p className="text">Autogenerate Terminal ID</p>
									<div className="info-box">
										<figure className="img-box tooltip-hover-wrap">
											{infoIcon}
											<RavenToolTip
												textColor="white-light"
												color={`deep-green-light`}
												position={`top-left`}
												text="Select this option if you want us to handle the management of your Terminal ID and other background work included."
											/>
										</figure>
									</div>
								</div>
								{/* auto generate box end */}
								{/* auto assin content start */}
								{!autoAssign && (
									<div className="auto-assign-content-box">
										<RavenInputField
											type="text"
											value={assignFormData?.terminal_id}
											showError={showTerminalError}
											onChange={(e: any) => {
												if (e.target.value?.length < 9) {
													setShowTerminalError(false);
													setAssignFormData((prev) => {
														return { ...prev, terminal_id: e.target.value };
													});
												}
											}}
											color="deep-green-light"
											label="Terminal ID"
											placeholder="Enter Terminal ID"
											name="term_id"
											// maxSize={8}
										/>
										{/* select option start  */}
										<div
											className="select-option-box-wrap"
											style={{ marginTop: '2.4rem' }}
										>
											<label htmlFor="">
												Is this Terminal ID registered on NIBSS?
											</label>
											<div className={'export-modal__export-as--selector-wrap'}>
												{selectTypeList?.map((chi, idx) => {
													return (
														<div
															key={idx}
															onClick={() => {
																setSelectedType(chi);
																setAssignFormData((prev) => {
																	return {
																		...prev,
																		exist_nibbs:
																			chi === 'Yes, It is' ? true : false,
																	};
																});
															}}
															className={`selector-wrap__item ${
																selectedType === chi && 'selected'
															}`}
														>
															<figure>
																{selectedType === chi
																	? icons.radio_check
																	: icons.radio_unchecked}
															</figure>
															<p>{chi}</p>
														</div>
													);
												})}
											</div>
										</div>
										{/* select option end */}
									</div>
								)}
								{/* auto assin content end */}
							</div>
						</>
					)}
				</div>
			</RavenModal>

			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="user_single_transactions"
				pageTitle="Transaction Statement"
				buttonText="Generate Statement"
			/>
			<SettleMentAccountModal
				visible={showSettlement}
				onClose={() => {
					setShowSettlement(false);
				}}
			/>
			<DeleteModal
				onDelete={
					terminalInfo?.status === 1 ? handleDeactivate : handleReactivate
				}
				loading={loading}
				deleteText={terminalInfo?.status === 1 ? 'Deactivate' : 'Activate'}
				buttonColor={terminalInfo?.status !== 1 ? 'deep-green-light' : ''}
				visible={showActivate}
				onCancel={() => setShowactivate(false)}
				title={
					terminalInfo?.status === 1
						? `Deactivate BankBox ( ${terminalInfo?.serial || ''} )`
						: `Activate BankBox ( ${terminalInfo?.serial || ''} )`
				}
				text={
					terminalInfo?.status === 1
						? 'Deactivating a BankBox suspend it from recieving or sending out money, are you sure you want to proceed?'
						: 'Activating this BankBox will activate the sending and receiving feature of the BankBox.'
				}
			/>
			<ProccessSettleMentAccountModal
				onClose={() => {
					setRequestSettleMerchantModal(false);
				}}
				el={el}
				visible={requestSettleMerchantModal}
				onFinish={() => {
					setRefreshTable(!refreshTable);
				}}
			/>
		</>
	);
};

export default BankBoxUserPreview;
